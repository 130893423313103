<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-04 18:10:10
 * @LastEditTime: 2021-06-10 17:09:19
-->
<template>
	<div class="manhourDetail">
		<div class="manhourDetail-desc">
			<span class="manhourDetail-desc-title">{{manhourDetail.name}}</span>
			<span class="manhourDetail-desc-content">{{manhourDetail.details}}</span>
		</div>
		<div class="manhourDetail-title">
			<span>详细信息</span>
			<!-- <img src="@/assets/images/mobile/student/status2.png" alt=""> -->
		</div>
		<div class="manhourDetail-list">
			<div class="manhourDetail-list-item">
				<img class="list-item-icon" src="@/assets/images/mobile/student/icon6.png" alt="">
				<span class="list-item-label">时间：</span>
				 <span class="list-item-val">{{manhourDetail.postTime}}</span>
				<!-- <span
					class="list-item-val">{{`${dateFormat(manhourDetail.starTime)} 至${dateFormat(manhourDetail.endTime)?' '+dateFormat(manhourDetail.endTime):'今'}`}}</span> -->
			</div>
			<div class="manhourDetail-list-item">
				<img class="list-item-icon" src="@/assets/images/mobile/student/icon7.png" alt="">
				<span class="list-item-label">地址：</span>
				<span class="list-item-val">{{manhourDetail.address}}</span>
			</div>
			<div class="manhourDetail-list-item">
				<img class="list-item-icon" src="@/assets/images/mobile/student/icon8.png" alt="">
				<span class="list-item-label">类型：</span>
				<span class="list-item-val">{{manhourDetail.postTypeName}}</span>
			</div>
			<div class="manhourDetail-list-item">
				<img class="list-item-icon" src="@/assets/images/mobile/student/icon9.png" alt="">
				<span class="list-item-label">人数：</span>
				<span class="list-item-val">{{manhourDetail.maxPeo}}人</span>
			</div>
			<div class="manhourDetail-list-item">
				<img class="list-item-icon" src="@/assets/images/mobile/student/icon10.png" alt="">
				<span class="list-item-label">工资：</span>
				<span class="list-item-val">{{manhourDetail.salary}}元</span>
			</div>
			<div class="manhourDetail-list-item">
				<img class="list-item-icon" src="@/assets/images/mobile/student/icon11.png" alt="">
				<span class="list-item-label">联系人：</span>
				<span class="list-item-val">{{manhourDetail.contact}}</span>
			</div>
			<div class="manhourDetail-list-item">
				<img class="list-item-icon" src="@/assets/images/mobile/student/icon12.png" alt="">
				<span class="list-item-label">联系方式：</span>
				<span class="list-item-val">{{manhourDetail.contactPhone}}</span>
			</div>
		</div>
		<div v-if="signUpData.length > 0" class="manhourDetail-title">
			<span>报名信息</span>
		</div>
		<div v-if="signUpData.length > 0" class="manhourDetail-apply">
			<div v-for="(item, index) in signUpData" :key="index" class="manhourDetail-apply-item">
				<div class="apply-item-left">
					<img v-if="item.avatar" @click="handleFn('previewAvatar', item)" class="apply-item-avatar"
						:src="item.avatar" alt="">
					<img v-else class="apply-item-avatar" src="@/assets/images/mobile/common/avatar.png" alt="">
					<div class="apply-item-user">
						<div class="item-user-inner">
							<span class="item-user-name">{{item.name}}</span>
							<span class="item-user-num">{{item.studentNumber}}</span>
						</div>
						<span class="item-user-class">{{item.college}}{{item.faculty}}</span>
					</div>
				</div>
				<div class="apply-item-right">
					<span class="apply-item-hour">工时：{{item.workHours}} h</span>
					<img v-if="manhourDetail.workingHourStatus != 2" @click="handleFn('edithour', item)"
						class="apply-item-edit" src="@/assets/images/mobile/common/edit.png" alt="">
				</div>
			</div>
		</div>
		<div v-if="manhourDetail.workingSchedule" class="">
			<div class="manhourDetail-title">
				<span>排班表</span>
			</div>
			<div class="manhourDetail-xls">
				<div class="manhourDetail-xls-left">
					<img src="@/assets/images/mobile/student/jpg.png" alt="">
					<span>排班表.jpg</span>
				</div>
				<div @click="handleFn('previewXls')" class="manhourDetail-xls-right">
					查看
				</div>
			</div>
		</div>
		<div @click="submit" v-if="manhourDetail.workingHourStatus != 2&&manhourDetail.signUp&&manhourDetail.signUp.length > 0" class="manhourDetail-confirm">
			提交工时
		</div>
		<div @click="upLoadWorkingSchedule" v-if="!manhourDetail.workingScheduleUrl&&manhourDetail.signUp&&manhourDetail.signUp.length > 0&&manhourDetail.postType != 'S_TEMP'" class="manhourDetail-cancel">
			上传排班表
		</div>
		<el-upload class="uploadImage" style="display:none;" :action="upLoadUrl" :show-file-list="false"
			:before-upload="beforeUpload" :http-request="handleCustomRequest"></el-upload>
	</div>
</template>
<script>
	import {
		ImagePreview
	} from 'vant';
	import {
		manhourDetailMb,
		workingScheduleUploadMb,
		manhourUpdateAllMb,
	} from "@/api/mobile/manhour";
	import {
		upLoadFileMb
	} from "@/api/mobile/oss"
	import storage from 'store'
	export default {
		data() {
			return {
				manhourDetail: {
					// title: "校内临时岗位标题内容",
					// content: "我们旨在基于信任，通过技术和服务，发展我们的企业合作伙伴成长，推动他们的业务增长。我们追求成为100万家企业的首选合作伙伴，建立基于信任的合作。"
				},
				manHourId: "",
				manHourIpt: [],
				upLoadUrl: "",
				limitSize: 5,
				signUpData:[]
			}
		},
		mounted() {
			// if (storage.get("accessManhour")) {
			// 	this.manHourIpt = storage.get("accessManhour")
			// }
			this.manHourId = this.$route.query.id
			this.getManhourDetail()
		},
		methods: {
			dateFormat(intDate) {
				if (intDate) {
					return intDate.replace(":00", "")
				} else {
					return ""
				}
			},
			// 工时详情
			getManhourDetail() {
				let params = {
					id: this.manHourId
				}
				manhourDetailMb(this.manHourId, params).then((res) => {
					if (res.code == 0) {
						this.manhourDetail = res.data
						if(storage.get("signUpData")){
							this.signUpData = storage.get("signUpData")
						}else{
							storage.set("signUpData",res.data.signUp)
							this.signUpData = res.data.signUp
						}
						
						// if (this.manHourIpt.length > 0) {
						// 	this.manhourDetail.signUp.forEach(item => {
						// 		this.manHourIpt.forEach(citem => {
						// 			if (item.id == citem.iptId) {
						// 				item.workingHour = citem.iptVal
						// 			}
						// 		})
						// 	})
						// }
					}
					
				})
			},
			submit() {
				if(storage.get("signUpData")){
					let workHoursData = storage.get("signUpData")
					let workHoursArr = []
					workHoursData.forEach(item=>{
						if(item.workingHours.length>0){
							item.workingHours.forEach(el=>{
								if((el.state=='NO_SUBMIT'||el.state=='REJECT')&&el.workTime>0){
									workHoursArr.push(el)
								}
							})
						}
					})
					let params = workHoursArr.map(ite=>{
						return {
							workingHour: ite.workTime,
							workingHoursId: ite.id
						}
					})
					// console.log(params)
					// return
					if (params.length > 0) {
						manhourUpdateAllMb({
							workingHours: params
						}).then((res) => {
							if (res.code == 0) {
								this.$toast("工时已提交")
								storage.remove("signUpData")
								this.getManhourDetail()
							}
						})
					} else {
						this.$toast("暂无可提交工时")
					}
				}
				// let params = this.manhourDetail.signUp.map(item => {
				// 	return {
				// 		id: item.id,
				// 		workingHour: item.workingHour
				// 	}
				// })
				// if (params.length > 0) {
				// 	manhourUpdateAllMb({
				// 		workingHours: params
				// 	}).then((res) => {
				// 		if (res.code == 0) {
				// 			this.$toast("工时已提交")
				// 			this.getManhourDetail()
				// 		}
				// 	})
				// } else {
				// 	this.$toast("暂无可提交工时")
				// }
			},
			handleFn(type, item) {
				switch (type) {
					case "previewXls":
						ImagePreview([this.manhourDetail.workingScheduleUrl]);
						break;
					case "previewAvatar":
						ImagePreview([item.avatar]);
						break;
					case "edithour":
						this.$router.push({
							path: `/mb/manhour/edithour?id=${item.id}&&manHourId=${this.manHourId}&&postType=${this.manhourDetail.postType}`
						})
						break;
				}
			},
			upLoadWorkingSchedule() {
				document.querySelector(".uploadImage input").click();
			},
			// 上传按钮   限制图片大小
			beforeUpload(file) {
				console.log("beforeUpload", file);
				const isLt5M = file.size / 1024 / 1024 < this.limitSize;
				if (!isLt5M) {
					this.$toast("上传文件大小不能超过 5MB!")
					return false;
				}
				let fileType = file.name.substring(file.name.lastIndexOf(".") + 1);
				let fileName = file.name;
				// let fileType = file.type;
				if (fileType != "png" && fileType != "jpg" && fileType != "jpeg" && fileType != "webp") {
					this.$toast("上传文件只能是jpg、png、jpeg、webp格式!")
					return false;
				}
			},
			handleCustomRequest(data) {
				this.fileData = []
				const formData = new FormData();
				formData.append('file', data.file);
				console.log("handleCustomRequest", formData)
				upLoadFileMb(formData).then((res) => {
					console.log("上传成功", res)
					// this.userInfo.avatar = res.data.accessUrl
					let params = {
						id: this.manHourId,
						workingScheduleUrl: res.data.accessUrl
					}
					workingScheduleUploadMb(params).then((res) => {
						if (res.code == 0) {
							this.$toast("排班表上传成功")
							this.getManhourDetail()
						}
					})
				})
			},
		},
	}
</script>
<style lang="scss" scoped>
	.manhourDetail {
		display: flex;
		flex-direction: column;

		.manhourDetail-desc {
			display: flex;
			flex-direction: column;
			background: #FFFFFF;
			padding: 0.28rem 0.28rem;
			box-sizing: border-box;
			margin-top: 0.16rem;

			.manhourDetail-desc-title {
				font-size: 0.36rem;
				font-family: PingFang SC;
				font-weight: bold;
				line-height: 0.5rem;
				color: #303133;
				margin-bottom: 0.2rem;
			}

			.manhourDetail-desc-content {
				font-size: 0.28rem;
				font-family: PingFang SC;
				font-weight: 400;
				line-height: 0.40rem;
				color: #606266;
			}
		}

		.manhourDetail-title {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin: 0.28rem 0;

			span {
				font-size: 0.32rem;
				font-family: PingFang SC;
				font-weight: bold;
				line-height: 0.44rem;
				color: #303133;
				padding-left: 0.2rem;
				border-left: 0.08rem solid #4392FA;
			}

			img {
				width: 0.96rem;
				height: 0.34rem;
				margin-right: 0.28rem;
			}

			.manhourDetail-title-hour {
				font-size: 0.28rem;
				font-family: PingFang SC;
				font-weight: 400;
				line-height: 0.4rem;
				color: #15D2B7;
				border-left: 0;
				margin-right: 0.28rem;
			}
		}

		.manhourDetail-list {
			display: flex;
			flex-direction: column;
			background: #FFFFFF;
			padding: 0 0.28rem;
			box-sizing: border-box;
			margin-top: 0.16rem;

			.manhourDetail-list-item {
				display: flex;
				align-items: center;
				height: 0.94rem;
				border-bottom: 0.02rem solid #DCDFE6;

				.list-item-icon {
					width: 0.32rem;
					height: 0.32rem;
					margin-right: 0.16rem;
				}

				.list-item-label {
					font-size: 0.28rem;
					font-family: PingFang SC;
					font-weight: 400;
					line-height: 0.4rem;
					color: #909399;
				}

				.list-item-val {
					font-size: 0.28rem;
					font-family: PingFang SC;
					font-weight: 400;
					line-height: 0.4rem;
					color: #303133;
				}
			}
		}

		.manhourDetail-list :last-child {
			border: 0;
		}

		.manhourDetail-xls {
			display: flex;
			align-items: center;
			justify-content: space-between;
			background: #FFFFFF;
			box-shadow: 0rem 0.04rem 0.16rem rgba(220, 223, 230, 0.2000);
			padding: 0.2rem 0.28rem;
			box-sizing: border-box;
			margin-bottom: 0.16rem;

			.manhourDetail-xls-left {
				display: flex;
				align-items: center;

				img {
					width: 0.7rem;
					height: 0.8rem;
				}

				span {
					font-size: 0.28rem;
					font-family: PingFang SC;
					font-weight: 400;
					line-height: 0.4rem;
					color: #303133;
					margin-left: 0.2rem;
				}
			}

			.manhourDetail-xls-right {
				font-size: 0.28rem;
				font-family: PingFang SC;
				font-weight: 400;
				line-height: 0.4rem;
				color: #4392FA;
			}
		}

		.manhourDetail-confirm {
			width: 6.94rem;
			height: 0.8rem;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 0.08rem;
			font-size: 0.28rem;
			font-family: PingFang SC;
			font-weight: bold;
			color: #FFFFFF;
			background: #4392FA;
			margin: 0 auto;
		}

		.manhourDetail-cancel {
			width: 6.94rem;
			height: 0.8rem;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 0.08rem;
			font-size: 0.28rem;
			font-family: PingFang SC;
			font-weight: bold;
			color: #4392FA;
			border: 0.02rem solid #4392FA;
			margin: 0.2rem auto;
		}

		.manhourDetail-apply {
			display: flex;
			flex-direction: column;

			.manhourDetail-apply-item {
				display: flex;
				align-items: center;
				justify-content: space-between;
				background: #FFFFFF;
				margin-bottom: 0.16rem;
				padding: 0.3rem 0.3rem;
				box-sizing: border-box;

				.apply-item-left {
					display: flex;
					align-items: center;

					.apply-item-avatar {
						width: 0.9rem;
						height: 0.9rem;
						border-radius: 50%;
						margin-right: 0.22rem;
					}

					.apply-item-user {
						display: flex;
						flex-direction: column;

						.item-user-inner {
							display: flex;
							align-items: center;

							.item-user-name {
								font-size: 0.32rem;
								font-family: PingFang SC;
								font-weight: bold;
								line-height: 0.44rem;
								color: #303133;
								margin-right: 0.2rem;
							}

							.item-user-num {
								font-size: 0.28rem;
								font-family: PingFang SC;
								font-weight: 400;
								line-height: 0.44rem;
								color: #606266;
							}
						}

						.item-user-class {
							font-size: 0.24rem;
							font-family: PingFang SC;
							font-weight: 400;
							line-height: 0.34rem;
							color: #909399;
							margin-top: 0.1rem;
						}
					}
				}

				.apply-item-right {
					display: flex;
					flex-direction: column;
					align-items: flex-end;

					.apply-item-hour {
						font-size: 0.28rem;
						font-family: PingFang SC;
						font-weight: 400;
						line-height: 0.4rem;
						color: #606266;
					}

					.apply-item-edit {
						width: 0.28rem;
						height: 0.28rem;
						margin-top: 0.14rem;
					}
				}
			}
		}
	}
</style>
